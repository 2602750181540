export const tabsCollections = [
    { type: "price", label: "Цена", title: "Фильтры по цене" },
    { type: "bodyType", label: "Кузов", title: "Фильтр по кузову" }
];

export const priceCollections = [
    {
        id: 1,
        img: "/img/car-by-price/price-collection-1.webp",
        name: "до 300 000 ₽",
        filter: { priceTo: 300_000 }
    },
    {
        id: 2,
        img: "/img/car-by-price/price-collection-2.webp",
        name: "до 500 000 ₽",
        filter: { priceFrom: 300_000, priceTo: 500_000 }
    },
    {
        id: 3,
        img: "/img/car-by-price/price-collection-3.webp",
        name: "до 1 500 000 ₽",
        filter: { priceFrom: 500_000, priceTo: 1_500_000 }
    },
    {
        id: 4,
        img: "/img/car-by-price/price-collection-4.webp",
        name: "от 3 000 000 ₽",
        filter: { priceFrom: 3_000_000 }
    }
];

export const bodyTypeCollections = [
    {
        id: 1,
        img: "/img/car-body-type/sedan.webp",
        name: "Седан",
        filter: { bodyType: "седан" }
    },
    {
        id: 2,
        img: "/img/car-body-type/hatchback.webp",
        name: "Хэтчбэк",
        filter: { bodyType: "хэтчбек" }
    },
    {
        id: 3,
        img: "/img/car-body-type/vnedorozhnik.webp",
        name: "Внедорожник",
        filter: { bodyType: "внедорожник" }
    },
    {
        id: 4,
        img: "/img/car-body-type/universal.webp",
        name: "Универсал",
        filter: { bodyType: "универсал" }
    },
    {
        id: 5,
        img: "/img/car-body-type/liftback.webp",
        name: "Лифтбэк",
        filter: { bodyType: "лифтбек" }
    }
];