import React from "react";
import Image from "next/image";
import classNames from "classnames";
import { FilterCardProps } from "./types";
import classes from "./FilterCard.module.scss";

export const FilterCard = ({ img, name, callback }: FilterCardProps) => {
    return (
        <div
            className={classNames("keen-slider__slide", classes["filter-card"])}
            onClick={callback}
        >
            <div className={classes["filter-card__wrapper"]}>
                <Image
                    className={classes["filter-card__image"]}
                    src={img}
                    alt={`Подборка автомобилей - ${name}`}
                    width={240}
                    height={160}
                    sizes="(max-width: 600px) 160px, (max-width: 1024px) 190px, 240px"
                />
            </div>

            <div className={classes["filter-card__name"]}>{name}</div>
        </div>
    );
};